<template>
    <div class="dashboard">
        <router-link :to="{ name: 'users' }" class="back-btn mb-3">
            <b-icon icon="chevron-left"></b-icon>
            <span>назад к списку</span>
        </router-link>
        <personal-data/>
    </div>
</template>

<script>
    import PersonalData from '@/components/forms/user/PersonalData'

    export default {
        name: "user-add",
        components: {
            PersonalData
        }
    }
</script>

<style scoped>

</style>